<template>
  <div
    class="white black--text mt-5 mb-5 pt-6 pb-6 pl-8 pr-8 d-flex align-center rounded-lg"
  >
    <div class="font-weight-bold info--number text-truncate" cols="1">
      {{ item.code }}
    </div>
    <div cols="3" class="info--name text-truncate">{{ item.name }}</div>
    <div cols="3" class="info--description text-truncate">
      {{ item.description }}
    </div>
    <div cols="1" class="info--speed">{{ item.speed }} MB</div>
    <div cols="1" class="info--price font-weight-bold">
      {{ getFormatPrice(item.price) }}
    </div>
    <div cols="2" class="info--status font-weight-bold success--text">
      {{ item.status }}
    </div>
    <div cols="2" class="info--actions">
      <v-btn icon @click="dialogDelete = true">
        <icon-delete></icon-delete>
      </v-btn>
      <v-btn icon @click="$emit('edit', item)">
        <icon-edit></icon-edit>
      </v-btn>
      <v-btn
        icon
        @click="$emit('changeComponent', 'InternetPlansDetails', item)"
      >
        <icon-eye></icon-eye>
      </v-btn>
    </div>

    <modal-delete
      v-if="dialogDelete"
      :dialogDelete="dialogDelete"
      :body="body"
      @cancel="dialogDelete = false"
      @delete="$emit('delete', item)"
    ></modal-delete>
  </div>
</template>

<script>
// Mixins
import { FormatsMixin } from "../../../mixins/Formats";

export default {
  name: "InternetPlansTableItem",
  mixins: [FormatsMixin],
  components: {
    IconDelete: () => import("../../icons/IconDelete"),
    IconEdit: () => import("../../icons/IconEdit"),
    IconEye: () => import("../../icons/IconEye"),
    ModalDelete: () => import("../../base/ModalDelete"),
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    dialogDelete: false,
    body: {
      title: "ELIMINAR PLAN DE INTERNET",
      type: "PLAN",
      question:
        "¿Esta seguro que quiere eliminar este plan de internet, una vez confirmada esta acción ya no se puede deshacer?",
    },
  }),
};
</script>

<style lang="scss" scoped>
</style>